import * as React from "react"

import Section from "../components/Section"
import Seo from "../components/seo"

const PolicyPage = () => (
  <>
    <Seo title="Polityka prywatności"/>
      <Section title="Polityka prywatności" _id="policy">
        <article className="policy__article">
          <h2 className="policy__article__heading">Informacje</h2>

          <p>
            Niniejsza polityka prywatności i plików cookies opisuje zasady
            postępowania z danymi osobowymi oraz wykorzystywania plików cookies
            i innych technologii w ramach strony internetowej
            http://adept.tychy.pl/ Administratorem strony jest Marta Lesiak,
            prowadzący działalność gospodarczą pod firmą Ośrodek Szkolenia
            Kierowców Adept Marta Lesiak z siedzibą w Tychy ul. Generała De
            Gaullea 47/19, 43-100 Tychy wpisana do Centralnej Ewidencji i
            Informacji o Działalności Gospodarczej, posługujący się numerem NIP
            6461193678. Kontakt z administratorem możliwy pod adresem e-mail
            kontakt@adept.tychy.pl
          </p>
        </article>
        <article className="policy__article">
          <h2 className="policy__article__heading">§1 Definicje</h2>

          <ol className="policy-list">
            <li>
              Na potrzeby niniejszej polityki prywatności, przyjmuje się
              następujące znaczenie poniższych pojęć:
              <ul className="policy-sublist">
                <li>
                  Administrator – Marta Lesiak, prowadzący działalność
                  gospodarczą pod firmą Ośrodek Szkolenia Kierowców Adept Marta
                  Lesiak z siedzibą w Tychy ul. Generała De Gaullea 47/19,
                  43-100 Tychy wpisana do Centralnej Ewidencji i Informacji o
                  Działalności Gospodarczej, posługujący się numerem NIP
                  6461193678.
                </li>
                <li>
                  Strona – strona internetowa dostępna pod adresem
                  http://adept.tychy.pl/
                </li>
                <li>Użytkownik – każdy podmiot, który korzysta ze Strony.</li>
              </ul>
            </li>
          </ol>
        </article>
        <article className="policy__article">
          <h2 className="policy__article__heading">§2 Dane osobowe</h2>

          <ol className="policy-list">
            <li>
              Użytkownik może przekazywać swoje dane osobowe Administratorowi za
              pomocą formularzy dostępnych na Stronie, takich jak formularz
              kontaktowy.
            </li>
            <li>
              Administratorem danych osobowych Użytkownika jest Administrator.
            </li>
            <li>
              Administrator gwarantuje poufność wszelkich przekazanych mu danych
              osobowych.
            </li>
            <li>
              Podanie danych jest zawsze dobrowolne, ale niezbędne do podjęcia
              przez Użytkownika akcji, do której przeznaczony jest dany
              formularz.
            </li>
            <li>
              Administrator nie udostępnia przekazanych mu danych jakimkolwiek
              podmiotom trzecim.
            </li>
            <li>
              Dane osobowe są gromadzone z należytą starannością i odpowiednio
              chronione przed dostępem do nich przez osoby do tego
              nieupoważnione.
            </li>
            <li>
              Użytkownikowi przysługują następujące uprawnienia:
              <ul className="policy-sublist">
                <li>
                  prawo do żądania dostępu do danych osobowych dotyczących
                  osoby, której dane dotyczą, ich sprostowania, usunięcia lub
                  ograniczenia przetwarzania,
                </li>
                <li>prawo do wniesienia sprzeciwu wobec przetwarzania,</li>
                <li>prawo do przenoszenia danych,</li>
                <li>
                  prawo do cofnięcia zgody na przetwarzanie danych osobowych w
                  określonym celu, jeżeli Użytkownik uprzednio taką zgodę
                  wyraził,
                </li>
                <li>
                  prawo do wniesienia skargi do organu nadzorczego w związku z
                  przetwarzaniem danych osobowych przez Administratora.
                </li>
              </ul>
            </li>
            <li>
              Administrator informuje niniejszym Użytkownika, że powierza
              przetwarzanie danych osobowych następującym podmiotom:
              <ul className="policy-sublist">
                <li>
                  EmailJS – w celu korzystania z systemu mailingowego EmailJS,
                  służącego do przesyłania wiadomości.
                </li>
                <li>
                  Google Analytics – w celu pozyskiwania aktualnych statystyk na
                  stronie.
                </li>
              </ul>
            </li>
          </ol>
        </article>
        <article className="policy__article">
          <h2 className="policy__article__heading">§3 Pliki cookies</h2>

          <ul className="policy-list">
            <li>
              Administrator wykorzystuje pliki cookies (ciasteczka), czyli
              niewielkie informacje tekstowe, przechowywane na urządzeniu
              końcowym Użytkownika. Cookies mogą być odczytywane przez system
              teleinformatyczny Administratora.
            </li>
            <li>
              Administrator przechowuje pliki cookies na urządzeniu końcowym
              Użytkownika, a następnie uzyskuje dostęp do informacji w nich
              zawartych w celach statystycznych, w celach marketingowych oraz w
              celu zapewnienia prawidłowego działania Strony.
            </li>
            <li>
              Administrator przechowuje pliki cookies na urządzeniu końcowym
              Użytkownika, a następnie uzyskuje dostęp do informacji w nich
              zawartych w celach statystycznych, w celach marketingowych oraz w
              celu zapewnienia prawidłowego działania Strony.
            </li>
            <li>
              Administrator informuje niniejszym Użytkownika, że istnieje
              możliwość takiej konfiguracji przeglądarki internetowej, która
              uniemożliwia przechowywanie plików cookies na urządzeniu końcowym
              Użytkownika. W takiej sytuacji, korzystanie ze Strony przez
              Użytkownika może być utrudnione.
            </li>
            <li>
              Administrator wskazuje niniejszym, że pliki cookies mogą być przez
              Użytkownika usunięte po ich zapisaniu przez Administratora,
              poprzez odpowiednie funkcje przeglądarki internetowej, programy
              służące w tym celu lub skorzystanie z odpowiednich narzędzi
              dostępnych w ramach systemu operacyjnego, z którego korzysta
              Użytkownik.
            </li>
          </ul>
        </article>
        <article className="policy__article">
          <h2 className="policy__article__heading">§4 Logi serwera</h2>

          <ul className="policy-list">
            <li>
              Korzystanie ze Strony wiąże się z przesyłaniem zapytań do serwera,
              na którym przechowywana jest Strona.
            </li>
            <li>
              Każde zapytanie skierowane do serwera zapisywane jest w logach
              serwera. Logi obejmują m.in. adres IP Użytkownika, datę i czas
              serwera, informacje o przeglądarce internetowej i systemie
              operacyjnym, z jakiego korzysta Użytkownik.
            </li>
            <li>Logi zapisywane i przechowywane są na serwerze.</li>
            <li>
              Dane zapisane w logach serwera nie są kojarzone z konkretnymi
              osobami korzystającymi ze Strony i nie są wykorzystywane przez
              Administratora w celu identyfikacji Użytkownika.
            </li>
            <li>
              Logi serwera stanowią wyłącznie materiał pomocniczy służący do
              administrowania Stroną, a ich zawartość nie jest ujawniana nikomu
              poza osobami upoważnionymi do administrowania serwerem.
            </li>
          </ul>
        </article>
      </Section>

      <Section title="Początkujący kierowco, pamiętaj!" _id="pamietaj">
        <p>
          Pragniemy zwrócić uwagę na to że dokument który otrzymacie po zdaniu
          egzaminów, nie uczyni was profesjonalnymi kierowcami których
          umiejętności dorównywać będą zawodowym kierowcom rajdowym, a wypadek
          drogowy może przytrafić się na prawdę każdemu. Jest kilka rzeczy przed
          którymi chcemy przestrzec młodszych i mniej doświadczonych, tak aby w
          przyszłości na waszej drodze nie było wielkich problemów i tragedii.
          Zdecydowanym numerem 1 jest trzeźwość. Jeśli nie jesteście pewni co do
          tego poproście kogoś o podwózkę, przełóżcie spotkanie lub weźcie wolne
          od pracy. Dbacie w ten sposób nie tylko o swoje życie i zdrowie, ale
          też innych uczestników ruchu drogowego. Kolejną ważną rzeczą z którą
          możecie się spotkać to stan pojazdu który będziecie prowadzić. Jeśli
          znajomy lub ktoś z rodziny poprosi was żebyście kierowali jego pojazd,
          upewnijcie się w jakim jest stanie: wycieraczki, stan opon - bieżnik i
          wizualnie poziom powietrza, hamulce, światła mijania, światła
          przeciwmgielne, kierunkowskazy. Pamiętajcie też o dobrze ustawionych
          lusterkach oraz fotelu. Nikt nie karze wam zaglądać pod maskę i
          brudzić sobie rąk. Poziom oleju czy też płynu chłodniczego powinna
          wskazać kontrolka. Zapytajcie się też krótko czy z cudzym pojazdem
          może się coś dziać podczas jazdy, nikt nie lubi niespodzianek.
          Następną ważną rzeczą jest kultura na drodze, bądźmy mili nie tylko
          dla pieszych, ale też dla innych uczestników ruchu drogowego jak
          autobusy, motocykle, samochody ciężarowe czy rowery. Prędkość? Chyba
          nie trzeba się tu zbyt wiele rozpisywać, każdy z nas wie jakie
          konsekwencje niesie nie dostosowanie prędkości do warunków panujących
          na drodze. Czasem wystarcza nawet 10 km/h mniej żeby uniknąć śmierci
          lub ciężkiego kalectwa. I moglibyśmy ku przestrodze wstawiać tu masę
          filmików z internetu, ale wierzymy że na naszych zajęciach wpoimy wam
          najważniejsze wartości i zasady których powinniście przestrzegać. Po
          tych już najważniejszych kwestiach czas na bonus! Mianowicie przegląd
          samochodu i jego ubezpieczenie. O pojazd powinien dbać jego właściciel
          i na jego barkach spoczywa utrzymanie go w stanie nie zagrażającym
          życiu i zdrowiu jego samego czy też innych uczestników ruchu
          drogowego, co weryfikuje przegląd samochodu. Kontrola drogowa zwróci
          wam uwagę jeśli spóźnicie się o dzień lub dwa, ale w przypadku kiedy
          będzie długo po terminie ważności badania policjant z wydziału ruchu
          drogowego ma prawo zatrzymać dokumenty. W kwestii ubezpieczeń warto
          pilnować terminów, ponieważ ubezpieczalnia w przypadku opóźnienia może
          odmówić dalszej współpracy. Nawet 1 dzień kosztować nas może
          kilkadziesiąt złotych różnicy w składce.
        </p>
      </Section>
  </>
)

export default PolicyPage
